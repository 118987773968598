import { styled } from '@mui/material/styles';

const StyledSvg = styled('svg')<{ isDarkMode: boolean }>`
  & > path.char {
    fill: ${({ isDarkMode }) => (isDarkMode ? 'black' : 'white')};
  }
`;

interface LogoProps {
  ['data-test']?: string;
  darkMode?: boolean;
  isOA?: boolean;
}

export function Logo(props: Readonly<LogoProps>): JSX.Element {
  const { darkMode, isOA } = props;
  const viewBoxWidth = isOA ? 170 : 80;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${viewBoxWidth} 34`}
      width="100%"
      height="34"
      data-test={props['data-test']}
      isDarkMode={!!darkMode}
    >
      {isOA && (
        <>
          <path
            d="M85.569,19.378L88.835,11.018L91.987,19.378L85.569,19.378ZM88.036,9.464L81.48,25.774L83.124,25.774L85.089,20.703L92.513,20.703L94.5,25.774L96.168,25.774L89.772,9.464L88.036,9.464Z"
            className="char"
          />
          <path
            d="M105.785,25.774L105.785,13.987L104.346,13.987L104.346,20.178C104.346,20.772 104.277,21.343 104.14,21.914C104.003,22.508 103.797,23.01 103.5,23.444C103.203,23.878 102.838,24.244 102.404,24.495C101.97,24.769 101.422,24.906 100.805,24.906C99.663,24.906 98.863,24.632 98.406,24.084C97.927,23.536 97.675,22.736 97.653,21.662L97.653,13.987L96.214,13.987L96.214,21.64C96.214,22.348 96.282,22.964 96.442,23.513C96.602,24.061 96.83,24.518 97.173,24.906C97.493,25.294 97.904,25.569 98.452,25.797C98.978,26.003 99.594,26.094 100.348,26.094C101.239,26.094 102.038,25.888 102.769,25.454C103.478,25.043 104.026,24.449 104.414,23.65L104.46,23.65L104.46,25.774L105.785,25.774Z"
            className="char"
          />
          <path
            d="M117.731,25.774L117.731,9.464L116.292,9.464L116.292,16.226L116.246,16.226C116.087,15.792 115.881,15.426 115.607,15.107C115.31,14.787 114.99,14.513 114.625,14.284C114.259,14.079 113.871,13.919 113.46,13.805C113.026,13.69 112.592,13.645 112.158,13.645C111.267,13.645 110.49,13.805 109.828,14.147C109.142,14.49 108.594,14.947 108.16,15.495C107.726,16.066 107.384,16.728 107.178,17.482C106.972,18.259 106.858,19.036 106.858,19.881C106.858,20.726 106.972,21.503 107.178,22.256C107.384,23.01 107.726,23.673 108.16,24.244C108.594,24.815 109.142,25.272 109.828,25.591C110.49,25.934 111.267,26.094 112.158,26.094C112.592,26.094 113.026,26.025 113.46,25.911C113.871,25.797 114.282,25.614 114.67,25.386C115.059,25.18 115.401,24.906 115.698,24.586C115.995,24.266 116.201,23.924 116.361,23.536L116.406,23.536L116.406,25.774L117.731,25.774ZM108.297,19.881C108.297,19.264 108.366,18.67 108.526,18.053C108.663,17.459 108.891,16.934 109.211,16.454C109.508,15.975 109.919,15.586 110.399,15.289C110.878,14.992 111.472,14.855 112.158,14.855C112.911,14.855 113.551,14.992 114.099,15.289C114.625,15.586 115.036,15.975 115.378,16.454C115.698,16.934 115.927,17.459 116.087,18.053C116.224,18.67 116.292,19.264 116.292,19.881C116.292,20.497 116.224,21.091 116.087,21.685C115.927,22.302 115.698,22.827 115.378,23.307C115.036,23.787 114.625,24.175 114.099,24.449C113.551,24.746 112.911,24.906 112.158,24.906C111.472,24.906 110.878,24.746 110.399,24.449C109.919,24.175 109.508,23.787 109.211,23.307C108.891,22.827 108.663,22.302 108.526,21.685C108.366,21.091 108.297,20.497 108.297,19.881Z"
            className="char"
          />
          <path
            d="M119.353,9.464L119.353,11.772L120.792,11.772L120.792,9.464L119.353,9.464ZM119.353,13.987L119.353,25.774L120.792,25.774L120.792,13.987L119.353,13.987Z"
            className="char"
          />
          <path
            d="M130.888,19.058L123.259,19.058C123.327,18.533 123.441,18.008 123.647,17.482C123.83,16.98 124.081,16.523 124.401,16.135C124.721,15.746 125.109,15.449 125.566,15.198C126.023,14.97 126.548,14.855 127.142,14.855C127.713,14.855 128.238,14.97 128.695,15.198C129.152,15.449 129.54,15.746 129.86,16.135C130.18,16.523 130.431,16.98 130.614,17.482C130.774,17.985 130.865,18.51 130.888,19.058ZM123.259,20.269L132.327,20.269C132.35,19.447 132.282,18.624 132.099,17.825C131.916,17.025 131.619,16.34 131.208,15.701C130.774,15.107 130.226,14.604 129.563,14.216C128.901,13.827 128.078,13.645 127.142,13.645C126.205,13.645 125.406,13.827 124.743,14.17C124.058,14.536 123.51,15.015 123.076,15.586C122.642,16.18 122.322,16.843 122.139,17.596C121.934,18.35 121.82,19.104 121.82,19.881C121.82,20.726 121.934,21.503 122.139,22.256C122.322,23.01 122.642,23.673 123.076,24.244C123.51,24.815 124.058,25.272 124.743,25.591C125.406,25.934 126.205,26.094 127.142,26.094C128.627,26.094 129.792,25.728 130.614,24.997C131.413,24.266 131.985,23.239 132.282,21.891L130.842,21.891C130.614,22.805 130.203,23.536 129.609,24.084C129.015,24.632 128.193,24.906 127.142,24.906C126.457,24.906 125.863,24.769 125.383,24.472C124.903,24.175 124.492,23.81 124.172,23.376C123.853,22.942 123.624,22.439 123.487,21.868C123.327,21.32 123.259,20.794 123.259,20.269Z"
            className="char"
          />
          <path
            d="M133.104,13.987L133.104,25.774L134.543,25.774L134.543,18.898C134.566,18.327 134.658,17.779 134.84,17.277C135,16.797 135.252,16.363 135.571,15.997C135.868,15.655 136.257,15.358 136.713,15.152C137.147,14.947 137.673,14.855 138.244,14.855C138.815,14.855 139.318,14.947 139.706,15.129C140.094,15.312 140.391,15.563 140.62,15.883C140.848,16.203 141.008,16.569 141.099,17.003C141.191,17.437 141.236,17.916 141.236,18.419L141.236,25.774L142.675,25.774L142.675,18.19C142.675,17.482 142.607,16.865 142.47,16.294C142.333,15.723 142.104,15.267 141.762,14.855C141.419,14.467 140.985,14.17 140.437,13.964C139.866,13.759 139.18,13.645 138.358,13.645C137.513,13.645 136.759,13.85 136.051,14.284C135.343,14.718 134.863,15.312 134.589,16.02L134.543,16.02L134.543,13.987L133.104,13.987Z"
            className="char"
          />
          <path
            d="M152.566,17.688L154.005,17.688C153.845,16.34 153.32,15.312 152.452,14.65C151.561,13.987 150.488,13.645 149.231,13.645C148.34,13.645 147.541,13.805 146.856,14.147C146.147,14.49 145.576,14.924 145.097,15.495C144.617,16.066 144.274,16.706 144.023,17.459C143.772,18.213 143.658,19.036 143.658,19.881C143.658,20.726 143.772,21.525 144.023,22.279C144.274,23.033 144.617,23.695 145.097,24.266C145.576,24.838 146.147,25.272 146.856,25.591C147.541,25.934 148.34,26.094 149.231,26.094C150.579,26.094 151.675,25.706 152.521,24.883C153.366,24.084 153.891,22.987 154.097,21.548L152.658,21.548C152.612,22.028 152.498,22.485 152.292,22.896C152.087,23.307 151.835,23.65 151.538,23.947C151.241,24.244 150.876,24.495 150.488,24.655C150.099,24.815 149.665,24.906 149.231,24.906C148.546,24.906 147.952,24.769 147.427,24.472C146.901,24.175 146.49,23.81 146.147,23.33C145.805,22.85 145.531,22.325 145.371,21.708C145.188,21.114 145.097,20.497 145.097,19.881C145.097,19.264 145.188,18.647 145.371,18.03C145.531,17.437 145.805,16.911 146.147,16.431C146.49,15.952 146.901,15.586 147.427,15.289C147.952,14.992 148.546,14.855 149.231,14.855C150.191,14.855 150.944,15.107 151.47,15.609C151.995,16.112 152.361,16.797 152.566,17.688Z"
            className="char"
          />
          <path
            d="M163.439,19.058L155.81,19.058C155.878,18.533 155.992,18.008 156.198,17.482C156.381,16.98 156.632,16.523 156.952,16.135C157.272,15.746 157.66,15.449 158.117,15.198C158.574,14.97 159.099,14.855 159.693,14.855C160.264,14.855 160.789,14.97 161.246,15.198C161.703,15.449 162.091,15.746 162.411,16.135C162.731,16.523 162.982,16.98 163.165,17.482C163.325,17.985 163.416,18.51 163.439,19.058ZM155.81,20.269L164.878,20.269C164.901,19.447 164.832,18.624 164.65,17.825C164.467,17.025 164.17,16.34 163.759,15.701C163.325,15.107 162.777,14.604 162.114,14.216C161.452,13.827 160.629,13.645 159.693,13.645C158.756,13.645 157.957,13.827 157.294,14.17C156.609,14.536 156.061,15.015 155.627,15.586C155.193,16.18 154.873,16.843 154.69,17.596C154.485,18.35 154.371,19.104 154.371,19.881C154.371,20.726 154.485,21.503 154.69,22.256C154.873,23.01 155.193,23.673 155.627,24.244C156.061,24.815 156.609,25.272 157.294,25.591C157.957,25.934 158.756,26.094 159.693,26.094C161.178,26.094 162.343,25.728 163.165,24.997C163.964,24.266 164.535,23.239 164.832,21.891L163.393,21.891C163.165,22.805 162.754,23.536 162.16,24.084C161.566,24.632 160.744,24.906 159.693,24.906C159.008,24.906 158.414,24.769 157.934,24.472C157.454,24.175 157.043,23.81 156.723,23.376C156.404,22.942 156.175,22.439 156.038,21.868C155.878,21.32 155.81,20.794 155.81,20.269Z"
            className="char"
          />
        </>
      )}
      <path
        d="M9.8146 2.554C10.5186 2.554 11.0906 1.982 11.0906 1.277C11.0906 0.572 10.5186 0 9.8146 0C9.1096 0 8.5376 0.572 8.5376 1.277C8.5376 1.982 9.1096 2.554 9.8146 2.554Z"
        fill="#00AAFF"
      />
      <path
        d="M1.886 5.95288C0.844 5.95388 0 6.79888 0 7.83888C0 8.88188 0.845 9.72588 1.886 9.72588C2.928 9.72788 3.773 8.88188 3.773 7.83888C3.773 6.79888 2.928 5.95288 1.886 5.95288Z"
        fill="#00AAFF"
      />
      <path
        d="M9.21997 9.0292C9.53397 9.5792 9.94597 10.0472 10.423 10.4202C10.655 9.5272 11.136 8.7302 11.793 8.1122C11.459 7.8122 11.087 7.5622 10.687 7.3692C10.414 8.0592 9.90797 8.6562 9.21997 9.0292Z"
        fill="#FF7400"
      />
      <path
        d="M13.3319 11.438C12.2739 11.43 11.2489 11.066 10.4219 10.42C10.2969 10.904 10.2439 11.417 10.2769 11.944C10.3569 13.19 10.9019 14.296 11.7339 15.103C12.7869 14.182 13.3729 12.832 13.3319 11.438Z"
        fill="#FFE794"
      />
      <path
        d="M10.2773 11.9441C10.2433 11.4161 10.2973 10.9041 10.4233 10.4191C9.94525 10.0461 9.53425 9.57806 9.22125 9.02806C9.12025 9.08306 9.01625 9.13306 8.90925 9.17706C7.60925 9.71306 6.16725 9.34006 5.27325 8.35706C4.04125 9.65406 3.61125 11.6011 4.33625 13.3611C5.32225 15.7511 8.05625 16.8881 10.4453 15.9021C10.9293 15.7021 11.3603 15.4291 11.7343 15.1031C10.9033 14.2961 10.3573 13.1911 10.2773 11.9441Z"
        fill="#FFD400"
      />
      <path
        d="M8.90356 9.17607C9.01156 9.13207 9.11456 9.08107 9.21456 9.02807C9.11456 8.85207 9.02056 8.67207 8.94255 8.48007C8.72955 7.96407 8.61956 7.43208 8.59256 6.90308C8.01756 6.91108 7.43656 7.02208 6.87256 7.25408C6.24555 7.51308 5.70755 7.89507 5.26855 8.35707C6.16155 9.34007 7.60456 9.71207 8.90356 9.17607Z"
        fill="#009500"
      />
      <path
        d="M9.67231 3.62757C8.78231 2.93157 7.55431 2.71957 6.43631 3.18157C4.77931 3.86357 3.99031 5.75957 4.67331 7.41657C4.82031 7.77257 5.02731 8.08757 5.27331 8.35757C5.71231 7.89557 6.25031 7.51357 6.87731 7.25557C7.44131 7.02357 8.02231 6.91357 8.59731 6.90557C8.53531 5.71257 8.92431 4.54157 9.67231 3.62757Z"
        fill="#00B359"
      />
      <path
        d="M10.6901 7.36946C10.0431 7.05846 9.32907 6.89546 8.60107 6.90546C8.62807 7.43246 8.73807 7.96446 8.95107 8.48146C9.03007 8.67346 9.12307 8.85246 9.22307 9.02946C9.91207 8.65546 10.4171 8.05946 10.6901 7.36946Z"
        fill="#005100"
      />
      <path
        d="M10.6655 4.9421C10.4435 4.4051 10.0935 3.9611 9.66546 3.6261C8.91746 4.5401 8.53046 5.7111 8.59146 6.9051C9.31946 6.8951 10.0345 7.0581 10.6805 7.3691C10.9775 6.6161 10.9975 5.7511 10.6655 4.9421Z"
        fill="#006209"
      />
      <path
        d="M17.7733 4.83859C16.7673 2.39859 13.9743 1.23659 11.5343 2.24259C10.7803 2.55359 10.1483 3.03659 9.66431 3.62659C10.0913 3.96059 10.4413 4.40459 10.6623 4.94259C10.9953 5.74959 10.9763 6.61459 10.6783 7.36859C11.0783 7.56059 11.4503 7.81159 11.7843 8.1116C12.5743 7.36959 13.6163 6.88759 14.7823 6.81259C15.9833 6.73559 17.1093 7.10659 18.0003 7.77859C18.2293 6.83259 18.1723 5.80759 17.7733 4.83859Z"
        fill="#FF8C1A"
      />
      <path
        d="M14.7888 6.81392C13.6238 6.88892 12.5808 7.37092 11.7908 8.11292C12.2938 8.56392 12.7078 9.12892 12.9818 9.79592C13.2048 10.3339 13.3128 10.8899 13.3298 11.4389C13.9478 11.4439 14.5758 11.3279 15.1838 11.0779C16.6538 10.4709 17.6588 9.21692 18.0058 7.77992C17.1158 7.10791 15.9898 6.73692 14.7888 6.81392Z"
        fill="#FFC68D"
      />
      <path
        d="M11.796 8.11115C11.138 8.72815 10.658 9.52614 10.426 10.4191C11.252 11.0661 12.275 11.4301 13.335 11.4371C13.318 10.8881 13.21 10.3321 12.988 9.79414C12.712 9.12614 12.298 8.56215 11.796 8.11115Z"
        fill="#FFBA80"
      />
      <path
        d="M24.3628 11.0052C23.1858 9.79423 21.4858 9.18823 19.2588 9.18823C19.2318 9.18823 19.2058 9.19023 19.1778 9.19023C19.5978 9.85423 19.8638 10.6292 19.9178 11.4672C19.9288 11.6452 19.9288 11.8212 19.9208 11.9962C20.9018 12.1362 21.7068 12.5932 22.3288 13.3762C23.0858 14.3312 23.4638 15.6812 23.4638 17.4282C23.4638 19.1812 23.0858 20.5342 22.3288 21.4852C21.5728 22.4362 20.5488 22.9112 19.2588 22.9112C17.9708 22.9112 16.9418 22.4362 16.1748 21.4852C15.4068 20.5342 15.0228 19.1812 15.0228 17.4282C15.0228 17.1412 15.0378 16.8682 15.0588 16.6032C13.8748 16.5942 12.7808 16.1592 11.9378 15.4302C11.8398 16.0522 11.7908 16.7182 11.7908 17.4282C11.7908 20.2232 12.5788 22.3662 14.1568 23.8512C15.3338 25.0632 17.0338 25.6682 19.2588 25.6682C21.4858 25.6682 23.1858 25.0632 24.3628 23.8512C25.9328 22.3662 26.7178 20.2232 26.7178 17.4282C26.7178 14.5762 25.9328 12.4352 24.3628 11.0052Z"
        className="char"
      />
      <path
        d="M34.9036 17.2469C34.4966 16.5639 33.8326 16.2219 32.9166 16.2219C31.8136 16.2219 31.0566 16.7409 30.6446 17.7749C30.4316 18.3239 30.3236 19.0209 30.3236 19.8649C30.3236 21.2019 30.6836 22.1409 31.4006 22.6829C31.8276 22.9999 32.3326 23.1569 32.9156 23.1569C33.7606 23.1569 34.4066 22.8329 34.8516 22.1869C35.2956 21.5389 35.5176 20.6769 35.5176 19.5999C35.5176 18.7139 35.3116 17.9299 34.9036 17.2469ZM37.2126 15.0839C38.1516 16.0759 38.6196 17.5329 38.6196 19.4529C38.6196 21.4789 38.1616 23.0229 37.2436 24.0859C36.3276 25.1489 35.1466 25.6799 33.7026 25.6799C32.7806 25.6799 32.0166 25.4519 31.4066 24.9939C31.0746 24.7409 30.7496 24.3709 30.4306 23.8849V29.8809H27.4536V13.8469H30.3356V15.5479C30.6616 15.0489 31.0076 14.6539 31.3766 14.3649C32.0496 13.8519 32.8496 13.5939 33.7766 13.5939C35.1276 13.5939 36.2746 14.0909 37.2126 15.0839Z"
        className="char"
      />
      <path
        d="M43.07 16.6793C42.645 17.1173 42.377 17.7103 42.268 18.4593H47.45C47.396 17.6603 47.129 17.0553 46.649 16.6413C46.171 16.2263 45.574 16.0193 44.865 16.0193C44.092 16.0193 43.494 16.2393 43.07 16.6793ZM47.57 14.0793C48.372 14.4393 49.035 15.0063 49.557 15.7813C50.028 16.4643 50.335 17.2583 50.474 18.1613C50.555 18.6883 50.589 19.4503 50.574 20.4423H42.194C42.241 21.5973 42.642 22.4073 43.398 22.8713C43.856 23.1593 44.41 23.3043 45.056 23.3043C45.741 23.3043 46.297 23.1293 46.726 22.7763C46.96 22.5863 47.166 22.3223 47.345 21.9843H50.415C50.335 22.6673 49.964 23.3613 49.302 24.0643C48.272 25.1823 46.828 25.7423 44.973 25.7423C43.442 25.7423 42.091 25.2693 40.922 24.3253C39.75 23.3803 39.165 21.8463 39.165 19.7203C39.165 17.7273 39.695 16.1993 40.751 15.1373C41.807 14.0733 43.178 13.5403 44.863 13.5403C45.866 13.5403 46.768 13.7203 47.57 14.0793Z"
        className="char"
      />
      <path
        d="M60.6085 14.5071C61.3615 15.1301 61.7385 16.1611 61.7385 17.6051V25.3511H58.6565V18.3531C58.6565 17.7491 58.5765 17.2831 58.4165 16.9611C58.1215 16.3691 57.5625 16.0731 56.7395 16.0731C55.7265 16.0731 55.0315 16.5071 54.6545 17.3711C54.4585 17.8301 54.3615 18.4121 54.3615 19.1241V25.3511H51.3525V13.8681H54.2665V15.5451C54.6505 14.9551 55.0165 14.5291 55.3585 14.2691C55.9755 13.8061 56.7565 13.5721 57.7015 13.5721C58.8865 13.5721 59.8555 13.8841 60.6085 14.5071Z"
        className="char"
      />
      <path
        d="M66.1986 25.3555H62.3816L67.4366 17.3475L62.6276 9.63654H66.5516L69.3546 14.7555L72.2346 9.63654H76.0316L71.2226 17.2185L76.3306 25.3555H72.3406L69.3546 19.9955L66.1986 25.3555Z"
        className="char"
      />
      <path
        d="M78.5043 24.2922C78.4613 24.2682 78.3933 24.2552 78.2973 24.2552H78.1523V24.6072H78.3063C78.3783 24.6072 78.4323 24.6002 78.4693 24.5852C78.5363 24.5582 78.5693 24.5082 78.5693 24.4342C78.5693 24.3632 78.5483 24.3152 78.5043 24.2922ZM78.3203 24.1392C78.4403 24.1392 78.5283 24.1502 78.5843 24.1742C78.6853 24.2162 78.7343 24.2982 78.7343 24.4192C78.7343 24.5062 78.7043 24.5702 78.6413 24.6112C78.6073 24.6322 78.5603 24.6482 78.5003 24.6582C78.5753 24.6702 78.6323 24.7012 78.6663 24.7532C78.7023 24.8042 78.7203 24.8542 78.7203 24.9032V24.9742C78.7203 24.9972 78.7203 25.0202 78.7223 25.0462C78.7243 25.0722 78.7263 25.0882 78.7303 25.0962L78.7363 25.1082H78.5763C78.5753 25.1052 78.5743 25.1012 78.5733 25.0982C78.5723 25.0952 78.5723 25.0922 78.5723 25.0872L78.5683 25.0572V24.9802C78.5683 24.8682 78.5373 24.7942 78.4773 24.7582C78.4403 24.7372 78.3773 24.7272 78.2863 24.7272H78.1523V25.1082H77.9813V24.1392H78.3203ZM77.8003 24.0892C77.6523 24.2382 77.5793 24.4172 77.5793 24.6272C77.5793 24.8392 77.6523 25.0192 77.7993 25.1682C77.9473 25.3172 78.1263 25.3912 78.3373 25.3912C78.5483 25.3912 78.7273 25.3172 78.8753 25.1682C79.0223 25.0192 79.0963 24.8392 79.0963 24.6272C79.0963 24.4172 79.0223 24.2382 78.8753 24.0892C78.7263 23.9402 78.5473 23.8662 78.3373 23.8662C78.1273 23.8662 77.9493 23.9402 77.8003 24.0892ZM78.9623 25.2522C78.7903 25.4262 78.5813 25.5122 78.3373 25.5122C78.0933 25.5122 77.8843 25.4262 77.7133 25.2522C77.5423 25.0802 77.4573 24.8712 77.4573 24.6262C77.4573 24.3832 77.5433 24.1752 77.7153 24.0032C77.8863 23.8322 78.0943 23.7462 78.3373 23.7462C78.5813 23.7462 78.7903 23.8322 78.9623 24.0032C79.1343 24.1742 79.2203 24.3822 79.2203 24.6262C79.2203 24.8702 79.1343 25.0792 78.9623 25.2522Z"
        className="char"
      />
    </StyledSvg>
  );
}
