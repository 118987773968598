import * as yup from 'yup';

export default yup.object().shape({
  'network-code': yup.string(),
  'origin-product': yup.string().required().oneOf(['ADMOB', 'AD_MANAGER']),
  'publisher-id': yup
    .string()
    .required()
    .matches(/pub-[a-zA-Z0-9]+/),
  'redirect-url': yup.string().required('Invalid redirect-url value'),
  token: yup.string().required(),
});
