import { Grid } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core';
import { getCurrentLocale, getLocales, LANGUAGE_LOCAL_STORAGE_KEY } from '@openx/utils';

const DEFAULT_AUTOCOMPLETE_WIDTH = '128px';

export const LanguagePicker = (): JSX.Element => {
  const [langSymbol, setLangSymbol] = useState<string>(getCurrentLocale());

  const { i18n, t } = useTranslation();

  const opts = useMemo(() => getLocales(t), [t]);

  return (
    <Grid width={128} data-test="choose-language">
      <AutoComplete
        componentsProps={{ popper: { style: { minWidth: DEFAULT_AUTOCOMPLETE_WIDTH, width: 'fit-content' } } }}
        textFieldProps={{
          margin: 'dense',
        }}
        options={opts}
        disableClearable
        value={opts.find(lang => lang.id === langSymbol) || opts[0]}
        getOptionDisabled={option => langSymbol === option.id}
        getOptionLabel={option => option.name}
        onChange={(_, option) => {
          setLangSymbol(option.id);
          i18n.changeLanguage(option.id);
          window.localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, option.id);
        }}
      />
    </Grid>
  );
};
