import axios from 'axios';
import i18n from 'i18next';
import { useSnackbar } from 'notistack';
import { useState, useCallback } from 'react';

interface ContractResponse {
  status: string;
  contracts: {
    status: string;
  }[];
}

export const useContractFetch = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isContractLoading, setLoading] = useState<boolean>(true);
  const [contract, setContract] = useState<boolean>(false);
  const [contractError, setError] = useState<boolean>(false);

  const fetchContract = useCallback(
    async (pubId: string) => {
      setLoading(true);
      setError(false);

      try {
        const res = await axios.get<ContractResponse>(`/api/v1/contract/${pubId}`);

        if (!res.data?.contracts) {
          throw Error(i18n.t('Unknown response data: {res}', { res: JSON.stringify(res) }));
        }

        setContract(!!res.data.contracts.length);
      } catch (e) {
        setContract(false);
        setError(true);
        const msg = i18n.t('Failed to fetch contract for publisher id: {pubId}', { pubId });
        console.error(msg, e);
        enqueueSnackbar(msg);
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  return { contract, contractError, fetchContract, isContractLoading };
};
