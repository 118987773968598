import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { ApplicationPrefix } from '../../../../../constants';
import { Error, ErrorProps, FailureImage } from '../Error';

interface GeneralErrorProps extends Partial<ErrorProps> {
  onClick: (ev: SyntheticEvent<HTMLButtonElement>) => void;
  'data-test'?: string;
}

export function GeneralError(props: GeneralErrorProps): JSX.Element {
  const { actions, subtitle, titlePrefix, title } = props;
  const { t } = useTranslation();
  const pathname = window.location.pathname;

  const getPageTitle = () => {
    if (pathname.includes(ApplicationPrefix.Dsp)) {
      return 'DSP UI';
    }

    if (pathname.includes(ApplicationPrefix.Publisher)) {
      return 'PublisherUI';
    }

    return 'Unity';
  };

  return (
    <Error
      titlePrefix={titlePrefix || getPageTitle()}
      title={title || t('Ooops!')}
      subtitle={subtitle || t('Something went wrong. Please refresh your browser.')}
      PictureComponent={FailureImage}
      data-test={props['data-test'] || 'general-error'}
      actions={
        actions || [
          {
            children: t('Refresh'),
            color: 'secondary',
            'data-test': 'button-refresh',
            onClick: props.onClick,
            variant: 'contained',
          },
        ]
      }
    />
  );
}
