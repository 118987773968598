import Rollbar from 'rollbar';

import { ROLLBAR_ACCESS_TOKEN, CTA_VERSION } from 'config';

let rollbar;

interface UseRollbar {
  initialize: () => void;
  rollbar?: Rollbar;
}

export const useRollbar = (): UseRollbar => {
  const isProduction = process.env.NODE_ENV === 'production';
  const isLocalDevelopment = window.location.hostname === 'localhost';
  const isRollbarEnabled = isProduction && !isLocalDevelopment;

  function initialize(): void {
    if (isProduction && !rollbar) {
      rollbar = new Rollbar({
        accessToken: ROLLBAR_ACCESS_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: isRollbarEnabled,
        payload: {
          client: {
            javascript: {
              code_version: CTA_VERSION,
              guess_uncaught_frames: true,
              source_map_enabled: true,
            },
          },
          environment: window.location.hostname,
        },
      });
    }
  }

  return { initialize, rollbar };
};
