import { Divider, styled } from '@mui/material';

const StyledActionsContainerDiv = styled('div')`
  margin: ${({ theme }) => theme.spacing(3.75, -2.5, -4.375)};
`;

const StyledContentContainerDiv = styled('div')`
  background: ${({ theme }) => theme.palette.background.boxLight};
  padding: ${({ theme }) => theme.spacing(1.25, 2.5)};
`;

export const makeActionSection = () =>
  function ({ children }): JSX.Element {
    return (
      <StyledActionsContainerDiv>
        <Divider />
        <StyledContentContainerDiv>{children}</StyledContentContainerDiv>
        <Divider />
      </StyledActionsContainerDiv>
    );
  };
