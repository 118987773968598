import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Dropzone } from '@openx/components/core';

import { Details, Fields } from 'modules/landingPage/types';

interface CSVDropzoneProps {
  fieldName: Fields.BUNDLES | Fields.DOMAINS;
}

export const CSVDropzone = ({ fieldName }: CSVDropzoneProps) => {
  const {
    formState: { isSubmitting },
  } = useFormContext<Details>();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController<Details>({
    name: fieldName,
  });
  const { t } = useTranslation();

  return (
    <Dropzone
      helpMessage={t('CSV file format required')}
      disabled={isSubmitting}
      file={value}
      errorMessage={error?.message}
      onDropFile={file => {
        onChange(file);
      }}
      onCancel={() => {
        onChange(null);
      }}
    />
  );
};
