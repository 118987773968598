export enum GoogleProductName {
  ADMOB = 'ADMOB',
  AD_MANAGER = 'AD_MANAGER',
}

export enum Result {
  SUCCESS = 'SUCCESS',
  CANCEL = 'CANCEL',
  ERROR = 'ERROR',
  OTHER = 'OTHER',
}

export type Listener = (error: Error, data: GoogleData) => void;

export interface GoogleIntegrationState {
  data: GoogleData;
  error: Error;
  pending: boolean;
}

// CTA beta v2
export interface MessageBase {
  version: 'v1' | null | 'v2';
  token: string;
  type: MsgType;
}

export interface InitMessage extends MessageBase {
  type: MsgType.DATA_POINTS_REQUEST;
}

export interface CommonMessage extends MessageBase {
  'origin-product': GoogleProductName;
  'publisher-id': string;
}

export interface AckMessage extends CommonMessage {
  'ack-type': AckMsgType;
}

export interface GoogleData extends CommonMessage {
  // network code is only present for AD_MANAGER origin
  'network-code'?: string;
  'redirect-url': string;
}

export interface ResultData extends CommonMessage {
  'result-value': Result;
  'keep-window-open'?: boolean;
  'result-details'?: string;
}

export enum MsgType {
  DATA_POINTS_REQUEST = 'DATA_POINTS_REQUEST',
  DATA_POINTS = 'DATA_POINTS',
  RESULT = 'RESULT',
  ACK = 'ACK',
  TEST = 'TEST',
  TEST_NEXT = 'TEST_NEXT',
}

export type AckMsgType = MsgType.DATA_POINTS | MsgType.RESULT | MsgType.TEST_NEXT;
