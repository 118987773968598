export enum AccountType {
  GAM = 'GAM',
  ADMOB = 'ADMOB',
}

export enum Fields {
  LEGAL_NAME = 'legal_name',
  WEBSITE = 'website',
  EMAILS = 'emails',
  DOMAINS = 'domains',
  BUNDLES = 'bundles',
  CONTRACT_SIGN_IN = 'contract_sign_in',
}

export interface Details {
  [Fields.LEGAL_NAME]: string;
  [Fields.WEBSITE]: string;
  [Fields.EMAILS]: string[];
  [Fields.DOMAINS]: File | null;
  [Fields.BUNDLES]: File | null;
  [Fields.CONTRACT_SIGN_IN]: boolean;
}

export interface PayloadContractField extends Pick<Details, Fields.LEGAL_NAME | Fields.WEBSITE | Fields.EMAILS> {
  google_pub_id: string;
  google_network_code?: string;
  google_account_type: AccountType;
}
