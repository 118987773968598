import { createRoot } from 'react-dom/client';

import { EN_LOCALE, getCurrentLocale, i18nConfigure, JP_LOCALE, initGA } from '@openx/utils';

import { initialize as initializeAnalytics } from 'modules/analytics';
import { initialize as initializeGoogleIntegration } from 'modules/googleIntegration';

import { App } from './App';
import { GA_TRACKING_ID, GA_ORIGIN_DOMAIN } from './config';
import translationJP from './modules/i18n/locales/jp/translations.json';

initializeAnalytics();
initializeGoogleIntegration();
initGA(GA_TRACKING_ID, !window.origin.includes(GA_ORIGIN_DOMAIN));

i18nConfigure({
  lng: getCurrentLocale(),
  resources: {
    [JP_LOCALE]: {
      translation: translationJP,
    },
  },
  supportedLngs: [EN_LOCALE, JP_LOCALE],
});

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(<App />);
