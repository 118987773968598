import { useMemo, useState, useCallback, ReactNode, createContext } from 'react';

import { GoogleData } from 'modules/googleIntegration';
import { Details } from 'modules/landingPage';

export interface State {
  currentStep: number;
  details?: Details;
  googleData?: GoogleData;
  alreadySigned: boolean;
}

export const initialState: State = {
  alreadySigned: false,
  currentStep: 0,
};

interface CtaProviderProps {
  children: ReactNode;
  googleData: GoogleData;
  alreadySignedPublisher: boolean;
}

export interface CtaContextProps {
  state: State;
  setDetails: (details: Details) => void;
  handleNextStep: () => void;
  handleBackStep: () => void;
}

const contextDefaultValues: CtaContextProps = {
  handleBackStep: () => {},
  handleNextStep: () => {},
  setDetails: () => {},
  state: initialState,
};

export const CtaContext = createContext(contextDefaultValues);

export function CtaProvider({ children, googleData, alreadySignedPublisher }: CtaProviderProps) {
  const [currentStep, setCurrentStep] = useState<number>(+alreadySignedPublisher);
  const [details, setDetailsState] = useState<Details>();

  const handleNextStep = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const handleBackStep = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const setDetails = useCallback((details: Details) => {
    setDetailsState(details);
  }, []);

  const contextValue = useMemo(() => {
    return {
      handleBackStep,
      handleNextStep,
      setDetails,
      state: { alreadySigned: alreadySignedPublisher, currentStep, details, googleData },
    };
  }, [currentStep, details, googleData, alreadySignedPublisher, handleNextStep, setDetails, handleBackStep]);

  return <CtaContext.Provider value={contextValue}>{children}</CtaContext.Provider>;
}
